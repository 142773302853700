<template>
    <div class="RecordManagerWrap">
        <div class="tableWrap">

            <div class="searchWrap">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="70px" class="demo-ruleForm" :inline="true">

                    <el-form-item label="评价" prop="rating">
                        <el-select v-model="ruleForm.rating" placeholder="请选择" @change="ratingChange">
                            <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="应用">
                        <searchSel @inpute="searchSelInput"></searchSel>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" @click="onSubmit">查询</el-button>
                    </el-form-item>

                </el-form>
            </div>

            <el-table :data="tableData" style="width: 100%;padding-right: 30px;" border stripe 
            class="tableClass" row-key="questionId" :expand-row-keys="expands" @row-click="rowClick" >
                <el-table-column
                label="序号"
                type="index"
                align="center"
                width="50">
                </el-table-column>
                <el-table-column
                label="创建用户"
                prop="username">
                    <template slot-scope="scope">
                        <div v-if="scope.row.userId>0" class="user-info">
                            <img class="avatar-box" :src="scope.row.avatar"/> 
                            <div class="username-box">{{ scope.row.username }}</div>
                            <div>{{ scope.row.mobile }}</div>
                        </div>
                        <div v-else>
                            <div>游客</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column type="expand" width="1" class="expandClass" style="opacity: 0;">
                    <template slot-scope="props">
                        <div class="detailWrapOuter">
                            <div class="detailWrap">
                                <div class="detailTitle">提问：</div>
                                <div class="detailQue" v-html="props.row.question"></div>
                            </div>
                            <div class="detailWrap">
                                <div class="detailTitle">答案：</div>
                                <div class="detailQue">{{ richToText(props.row.answer) }}</div>
                            </div>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column
                    prop="question"
                    label="提问">
                    <template slot-scope="scope">
                        <div class="question" v-html="scope.row.question"></div>
                    </template>
                </el-table-column>

                <el-table-column
                    prop="answer"
                    label="回答">
                    <template slot-scope="scope">
                        <div class="questionText">{{richToText(scope.row.answer)}}</div>
                    </template>
                </el-table-column>

                <el-table-column
                    prop="gptKey"
                    width="100"
                    label="key后四位">
                    <template slot-scope="scope">
                        <div>{{ scope.row.gptKey | filterKey }}</div>
                    </template>
                </el-table-column>

                <el-table-column
                    prop="time"
                    width="160"
                    label="提问时间">
                    <template slot-scope="scope">
                        <div class="time"> {{ timeFilter(scope.row.time) }}</div>
                    </template>
                </el-table-column>

                <el-table-column
                    prop="time"
                    width="100"
                    label="响应时长">
                    <template slot-scope="scope">
                        <div class="time"> {{ scope.row | responseTime }}</div>
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="100">
                    <template slot-scope="scope">

                        <el-button @click.stop="detailClick(scope.row)" type="text" size="small">
                            查看上下文
                        </el-button>
                        
                    </template>
                </el-table-column>

            </el-table>
            <br>
            <el-pagination
                class="pageBtn"
                background
                @current-change="handleCurrentChange"
                :current-page="pageNumber"
                :page-sizes="[20, 50, 100, 150]"
                :page-size="pageSize"
                layout="total, prev, pager, next"
                :total="total">
            </el-pagination>

        </div>

        <QuestionDetail :visible.sync="QuestionDetailVisible" ref="QuestionDetailRef" :showKey="'name'"></QuestionDetail>

    </div>
</template>
  
<script>

import { mapState } from 'vuex'
import $ from "jquery"
import { marked } from 'marked'
import QuestionDetail from './QuestionDetail.vue'
import searchSel from "../../components/searchSel/searchSel.vue"

export default {
    components: {QuestionDetail, searchSel},
    props:[],
    data() {
        return {
            appId:null,
            pageNumber:1,
            pageSize:20,
            total:0,
            tableData:[],
            similarDialogVisible:false,
            QuestionDetailVisible:false,

            ruleForm:{
                rating:-1
            },
            rules:{
                
            },
            options:[
                {
                    value: -1,
                    label: '全部'
                },{
                    value: 0,
                    label: '不评价'
                },{
                    value: 1,
                    label: '赞'
                },{
                    value: 2,
                    label: '踩'
                }
            ],

            // 要展开的行，数值的元素是row的key值
            expands: []
        }
    },
    async mounted(){

        this.load()

    },
    filters:{
        filterKey(val){
            
            if(val){
                //截取字符串最后四位
                return val.substring(val.length-4)
            }else{
                return ""
            }
            
        },
        responseTime(item){
            if(!item.answerTime){
                return ""
            }
            var t1 = new Date(item.answerTime).getTime()
            var t2 = new Date(item.time).getTime()
            var t3 = (t1 - t2)/1000

            return t3
        }
    },
    computed:{

    },
    methods:{
        async load(){
            var param = {pageNumber:this.pageNumber, pageSize:this.pageSize}
            if(this.appId){
                param.appId = this.appId
            }
            if(this.ruleForm.rating != -1){
                param.rating = this.ruleForm.rating
            }

            var res2 = await this.$https('queryQuestionAnswer', param)
            this.tableData = res2.data.records
            this.total = parseInt(res2.data.total)
        },
        editClick(item){
            
        },
        detailClick(item){
            this.QuestionDetailVisible = true
            this.$nextTick(()=>{
                var currentItem = {time:item.time, content:item.question, role:"user"}
                this.$refs.QuestionDetailRef.init(item.chatId, item.questionId, item.appId, currentItem)
            })
        },
        handleCurrentChange(num){
            this.pageNumber = num
            this.load()
        },
        onSubmit(){
            this.pageNumber = 1
            this.load()
        },
        ratingChange(){
            this.pageNumber = 1
            this.load()
        },
        richToText(richText){
            var htmlText = marked(richText)
            var text = $(htmlText).text()
            return text
        },
        rowClick(row, event, column) {
            if (this.expands.indexOf(row.questionId) < 0) {
                this.expands.push(row.questionId);
            } else {
                var index = this.expands.indexOf(row.questionId);
                this.expands.splice(index, 1);
            }
        },
        timeFilter(str){
            var arr = str.split(":")
            return arr[0] + ":" + arr[1]
        },
        searchSelInput(item){

            this.appId = item.appId
            this.pageNumber = 1

            this.load()
        }

    }
}
</script>
  
<style scoped lang="scss">

.RecordManagerWrap{
    padding: 40px;
    height: 100%;
    overflow-y: auto;
}
.tableWrap{

}
.pageBtn{
    padding-bottom: 165px;
}
.questionText{
    display: -webkit-box;
    -webkit-line-clamp: 2; /* 设置行数，这里示例设置为2行 */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 21px;
}
::v-deep .question{
    display: -webkit-box;
    -webkit-line-clamp: 2; /* 设置行数，这里示例设置为2行 */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 21px;
    img {
        width: 50px;
    }
}

.user{
    display: flex;
    flex-direction: row;
    align-items: center;
    .avatar{
        width: 30px;
        height: 30px;
        border-radius: 30px;
        border: 1px solid #eee;
    }
    .nickName{
        font-size: 12px;
        color: #333;
        padding-left: 5px;
    }
}
.detailWrapOuter{
    background: rgb(248 240 255);
}
.detailWrap{
    display: flex;
    flex-direction: row;
    padding: 10px 20px;
}
.detailTitle{
    font-weight: bold;
    color: #000;
    width: 50px;
    line-height: 26px;
    flex-shrink: 0;
}
.detailQue{
    color: #686868;
    line-height: 26px;
}
.detailWrap:nth-child(1){
    padding-bottom: 4px;
}
.detailWrap:nth-child(2){
    padding-top: 4px;
}
.tableClass ::v-deep .el-table__expand-icon{
    display: none;
}

.time{
    color: #bbbbbb;
}
.user-info {
    display: flex;
    align-items: center;
    .avatar-box {
        width: 50px;
    }
    .username-box {
        margin: 0 5px;
    }
}
@media screen and (max-width: 900px) {
    
}
</style>