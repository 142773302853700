<template>
    <div class="searchApp" >

        <div class="selText" @click="inputClick">{{ showText }}</div>

        <i class="el-icon-close clearBtn" v-if="showText" @click="clearClick"></i>

        <searchSelDialog :visible.sync="searchSelDialogVisible" ref="searchSelDialogRef" @selSuccess="selSuccess"></searchSelDialog>

    </div>
  </template>
  
  <script>
    import { mapState } from 'vuex'
    import { marked } from 'marked'
    import searchSelDialog from "./searchSelDialog.vue"

    export default {
        data() {
            return {
                searchSelDialogVisible:false,
                showText:""

            }
        },

        props:{
            selete:String,
            showKey:String
        },
        
    
        components:{searchSelDialog},
        async mounted() {
            

        
        },
        watch: {
            
        },
        computed:{
            ...mapState([])
        },
  
        methods: {
            
            inputClick(){
                this.searchSelDialogVisible = true
                this.$nextTick(()=>{
                    this.$refs.searchSelDialogRef.init()
                })
            },

            selSuccess(item){
                // if(this.showKey){
                //     // this.$emit("update:selete", item[this.showKey])
                //     this.showText = item[this.showKey]
                // }else{
                //     // this.$emit("update:selete", item)
                //     this.showText = item
                // }

                this.showText = item.name

                this.$emit("inpute", item)

            },

            clearClick(){
                this.showText = ''
                this.$emit("inpute", {appId:null})

            }

        }
    }
  </script>
  <style lang="scss" scoped>

.searchApp{
    width: 200px;
    height: 40px;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    background: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.selText{
    font-size: 13px;
    color: #353535;
    width: 100%;
    height: 100%;
    padding: 8px;
    line-height: 25px;
    box-sizing: border-box;
    flex: 1;
}
.clearBtn{
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
}

    @media screen and (max-width: 900px){


        
        
    }

  </style>