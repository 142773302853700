<template>
    <div class="QuestionDetailWrap" v-if="visible"  >

        <div class="bg" @click="bgClick"></div>

        <div class="tableWrap"  ref="tableWrapRef">
            <div v-for="(item,index) in list" :key="index">

                <div v-if="item.role === 'user'">
                    <div class="item_user">
                        <div class="chat_items">
                            <div class="times">
                                <span>{{item.time}}</span>
                            </div>
                            <div v-html="item.content"></div>
                        </div>
                        <div class="chat_tx">
                            <img :src='item.avatar?item.avatar:"https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"' style="width:40px;height:40px;">
                        </div>
                    </div>
                    
                </div>

                <div v-if="item.role == 'assistant' || item.role == 'error'">
                    <div class="item_assistant">
                        <div class="chat_items" v-if="item.content" @click="chatItemClick">
                            <div class="times">
                                <div class="itemsText">{{item.time}}</div> <img v-if="item.modelType == 8" class="gpt4Icon" src="../../assets/talk4.png">
                            </div>
                            <div>
                                <div :class="{phone:phone}" v-highlight v-html="item.content" class="markdown-body" id="content"> 
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>

    </div>
  </template>
  
  <script>
  import { mapState } from 'vuex'
  import { marked } from 'marked'

  export default {
    data() {
      return {
        chatId:null,
        list:[],
        pageNumber:1,
        pageSize:25,
        lastPageId:null,
        firstPageId:null,
        isLast:false,
        isFirst:false,
        appId:null
      }
    },

    props:{
        visible:Boolean
    },
    
    components:{},
    async mounted() {
      
    },
    watch: {
        visible(){
            if(this.visible){

                this.isFinish = false


            }else{
            }
        }
    },
    computed:{
        ...mapState([]),

        width(){

            return "360px"
        },

        phone(){
            
        },

    },
  
    methods: {
        
        async init(chatId, chatDetailId, appId, currentItem){
            this.chatId = chatId
            this.appId = appId
            this.list = []
            this.list.push(currentItem)

            await this.loadData(false, chatDetailId)

            await this.loadData(true, chatDetailId)

        },
        async loadData(isUp, chatDetailId, appId){

            var res = await this.$https("queryChatDetail", {appId:this.appId, chatId:this.chatId, pageNumber:1, pageSize:this.pageSize, chatDetailId:chatDetailId, lessThan:isUp})
            console.log("获取对话记录", res)
            
            var scrollHeight = this.$refs.tableWrapRef.scrollHeight
            var scrollTop = this.$refs.tableWrapRef.scrollTop

            var scrollBottom = scrollHeight - scrollTop

            var list
            if(isUp){
                list =  res.data.reverse()
                for(var i = list.length-1; i >= 0; i--){
                    this.list.unshift(list[i])
                }
            }else{
                list =  res.data
                    for(var i = 0; i < list.length; i++){
                    this.list.push(list[i])
                }
            }
            
            this.list.map(item => {

                if(item.role == "user"){
                    // item.content = `<div class='normalContent'>${item.content}</div>`
                }else{
                    item.content = marked(item.content)
                }
            })


            if(isUp){
                this.$nextTick(()=>{
                    this.$refs.tableWrapRef.scrollTop = this.$refs.tableWrapRef.scrollHeight - scrollBottom
                })
            }
            console.log(this.list)

        },
        handleClose(){
            this.$emit('update:visible', false)
        },

        chatItemClick(e){
            e.preventDefault();
        },
        bgClick(){
            this.handleClose()
        },
        async handleScrollToTop(){
            console.log("到顶部了");

            if(this.isLast){
                return
            }
            var res = await this.$https("queryChatDetail", {chatId:this.chatId, pageNumber:1, pageSize:this.pageSize, chatDetailId:this.lastPageId})
            console.log("获取对话记录", res)

            if(!res.data){
                this.isLast = true
                return
            }

            this.lastPageId = res.data[res.data.length-1].id
            var list =  res.data.reverse()

            var scrollHeight = this.$refs.tableWrapRef.scrollHeight
            var scrollTop = this.$refs.tableWrapRef.scrollTop

            var scrollBottom = scrollHeight - scrollTop


            for(var i = list.length-1; i >= 0; i--){
                this.list.unshift(list[i])
            }

            this.$nextTick(()=>{
                this.$refs.tableWrapRef.scrollTop = this.$refs.tableWrapRef.scrollHeight - scrollBottom
            })


        },
        scrollToBottom() {
            this.$nextTick(() => {
                var container = this.$refs.tableWrapRef
                container.scrollTop = container.scrollHeight
            })
        }

    }
  }
  </script>
  <style lang="scss" scoped>

  .QuestionDetailWrap{
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
  }
    .bg{
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #0000008e;
    }
.tableWrap{
    padding: 13px 40px 40px;
    width: 900px;
    height: 100vh;
    background: #fff;
    position: fixed;
    right: 0;
    top: 0;
    overflow: auto;
}
.headerWrap{
    padding: 0px 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

}


.item_user {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;
    font-size: 18px;
    .chat_items {
        // overflow-x: auto;
        margin-right: 10px;
        margin-left: 50px;
        // max-width: 600px;
        border-radius: 10px;
        padding: 15px 15px 15px;
        background: #F3F9F2 100%;
        font-size: 14px;
        line-height: 25px;
        white-space: pre-wrap;
        .times {
            display: flex;
            flex-direction: row;
            font-size: 12px;
            color: #999999;
            line-height: 22px;
        }
        ol{
            padding: 0 20px;
        }
    }
}


.item_assistant {
    display: flex;
    .chat_items {
        background: #FBF5F8 100%;
        border-radius: 10px;
        padding: 15px;
        margin-left: 10px;
        margin-right: 50px;
        // overflow-x: auto;
        // max-width: 600px;
        .times {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 12px;
            color: #999999;
            .itemsText{
                padding-right: 10px;
            }
            .gpt4Icon{
                width: 27px;
                height: 11px;
            }
        }
        ol{
        padding: 0 20px;
        }
    }
}

@media screen and (max-width: 900px){


    
    
}

  </style>