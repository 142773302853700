<template>
  
    <el-dialog title=""
        :visible.sync="visible"
        width="500px"
        :close-on-click-modal="false"
        :before-close="handleClose"
        class="upload_box">


        <div class="wrap">

            <el-input v-model="name" @input="inputHandle"></el-input>
            <br>
            <el-table
                :data="tableData"
                style="width: 100%"
                @row-click="selBtnClick"
                border>


                <el-table-column
                    prop="name"
                    label="应用名称">
                    
                </el-table-column>

                <el-table-column
                    fixed="right"
                    label="操作"
                    width="80">
                    <template slot-scope="scope">


                        <el-button  @click.stop="selBtnClick(scope.row)"
                            type="text"
                            size="small">
                            选择
                        </el-button>
                        

                    </template>
                </el-table-column>
            
            </el-table>

            <br>
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageNumber"
                :page-size="pageSize"
                layout="total, prev, pager, next"
                :total="total">
            </el-pagination>



        </div>
            
    </el-dialog>
  
  </template>
  
  <script>
  // 引入组件及方法
  // 引入组件
  export default {
    components: {  },
    // 数据
    data() {
      return {
            name:"",
            tableData:[],
            total:0,
            pageNumber:1,
            pageSize:10,


      }
    },
    props:{
        visible:Boolean
    },
    watch: {
        visible(){
            if(this.visible){




            }
        },

        name(val){

        }
    },
    //  初始化
    mounted() {
    },
  
    methods: {

        init(){
            this.pageNumber = 1
            this.name = ""
            this.getData()

        },

        async getData(val){
            this.$https('GET_queryApps', {
                pageNumber: this.pageNumber,
                pageSize: this.pageSize,
                name:this.name,
                state:3
            }).then(res => {
                this.tableData = res.data.records
                this.total = parseInt(res.data.total)
            })

        },

        handleClose(){
            this.$emit('update:visible', false)
        },
        handleSubmit(){



        },
        selBtnClick(item){
            this.$emit("selSuccess", item)
            this.handleClose()
        },
        handleSizeChange(size) {
            this.pageSize = size
            this.getData()
        },
        handleCurrentChange(num) {
            this.pageNumber = num
            this.getData()
        },
        inputHandle(){
            this.pageNumber = 1
            this.getData()
        }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  
.wrap{
    height: 80vh;
}
  
  </style>
  